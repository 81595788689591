import { connect } from 'react-redux';
import { addToken } from '../actions/addToken'
import { useHistory } from "react-router-dom";
import React from 'react'


const HostedUiRedirectNewUser = ({dispatch}) => {
    const history = useHistory();

    try {
        const response = new URLSearchParams(window.location.hash.replace(/^\#/, ''));
        let jwt = response.get("id_token");
        console.log(jwt);
        dispatch(addToken(jwt));

        history.push("/newaccount");
    } catch (error) {
        console.log(error)
    }
    return (
        <div>
            Loading...
        </div>
    )
}

export default connect()(HostedUiRedirectNewUser)

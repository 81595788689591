import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleCheckedFilled from "@material-ui/icons/CheckCircle";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";

const withStyles = makeStyles(() => ({
  privacyLink: {
    color: "black",
    textDecoration: "none",
    fontSize: "0.75rem",
  },
  footerRoot: {
    backgroundColor: "white",
  },
  eulaWrapper: {
    maxHeight: "40vh",
    overflowY: "scroll",
    marginBottom: "2vh",
    padding: "10px",
    textAlign: "left",
  },
  eulaHeader: {
    fontSize: "18px",
    marginBottom: "15px",
    fontWeight: 700,
    color: "#32A19F",
    textTrasform: "capitalize",
  },
  main_page_block: {
    backgroundColor: "#fff",
    borderRadius: " 20px",
    color: "#000",
    width: "81%",
    margin: "0 auto",
    padding: "18px",
    boxShadow: "0px 6px 22px 0px rgb(91 91 91)",
  },
  main_border: {
    border: "1px solid #E4E2E2",
    borderRadius: " 5px",
    padding: "12px",
  },
  agreeButton: {
    backgroundColor: "#32A19F",
    borderRadius: " 16px",
    color: "#fff",
    lineHeight: "60px",
    padding: "0 24px",
  },
  backButton: {
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
    color: "#fff",
    textAlign: "left",
    marginTop: "12px",
    marginLeft: "4%",
    // width: "81%",
    // margin: "0 auto",
    fontSize: "20px",
    fontWeight: "400",
  },
}));

const EULA = (props) => {
  const classes = withStyles();
  return (
    <div>
      <div className={classes.main_page_block}>
        <div className={classes.main_border}>
          <Typography className={classes.eulaHeader}>
            My pay view license agreement
          </Typography>
          <div className={classes.eulaWrapper}>
            <Typography>
              CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS BEFORE USING THE
              APPLICATION. DO NOT USE THE APPLICATION IF YOU DO NOT AGREE TO
              THESE TERMS AND CONDITIONS. USING ANY PART OF THE APPLICATION
              INDICATES ACCEPTANCE AND AGREEMENT TO THE TERMS AND CONDITIONS OF
              THIS AGREEMENT.
              <br />
              1. LICENSE. Subject to the terms and conditions of license
              agreement (the “Agreement”), My Pay View, LLC (“My Pay View”)
              hereby grants to you (the “User”) a limited, non-exclusive,
              non-sublicensable, non-transferrable and revocable right to use
              the “My Pay View” application (the “Application”) as available on
              My Pay View's website from time to time. Except for foregoing
              license, no right, title or interest in and to the Application is
              transferred, or granted, to User.
              <br />
              2. DISCLAIMERS. The Application is intended solely for general
              information and preliminary analysis purposes, and is not intended
              in any way as financial advice or services. Any results from use
              of the Application are in no way a replacement for a thorough
              financial review, and User must always conduct a formal evaluation
              before taking any action or inaction. Any calculations performed
              by the Application are based on data and assumptions provided by
              User and third parties, and My Pay View shall bear no risks
              associated with such data and assumptions. My Pay View does not
              collect or store any financial account or banking information.
              Neither My Pay View nor its licensors (including, without
              limitation, Plaid Inc. (f.k.a. Plaid Technologies, Inc.)) is
              responsible for the accuracy of the Application or the User's use
              of, or actions taken as a result of, the Application. User retains
              the entire risk and associated liability as to the results and
              performance of the Application. Accordingly, User is expressly
              prohibited from copying, decompiling, reverse engineering,
              disassembling, attempting to derive the source code, modifying,
              transcribing, storing, translating, selling, leasing,
              transferring, distributing or creating derivative works of the
              Application. By accessing and using the Application, User agrees
              to indemnify and hold harmless My Pay View and its licensors from
              any claim or demand, including reasonable attorney's fees, arising
              out of or related in any way to User's access, inability to access
              or use of the Application.
              <br />
              THE APPLICATION IS PROVIDED “AS IS” AND “AS AVAILABLE” WITH ALL
              FAULTS AND WITHOUT WARRANTY OF ANY KIND. MY PAY VIEW MAKES NO
              REPRESENTATION OR WARRANTY (EXPRESS, IMPLIED OR STATUTORY) WITH
              RESPECT TO THE APPLICATION, INCLUDING, WITHOUT LIMITATION, ANY
              WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY,
              NONINFRINGEMENT, ACCURACY, VALIDITY, COMPLETENESS, APPLICABILITY,
              OR COMPLIANCE WITH ANY REGULATORY CODE OR GOVERNMENTAL LAWS AND
              REGULATIONS. MY PAY VIEW DOES NOT WARRANT THAT THE APPLICATION
              WILL OPERATE UNINTERRUPTED OR ERROR-FREE.
              <br />
              TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER MY PAY VIEW NOR
              ITS LICENSORS WILL BE LIABLE UNDER THESE TERMS FOR ANY (A)
              INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR
              PUNITIVE DAMAGES, (B) LOSS, ERROR, OR INTERRUPTION OF USE OR DATA
              (IN EACH CASE, WHETHER DIRECT OR INDIRECT), OR (C) COST OF COVER
              OR LOSS OF BUSINESS, REVENUES, OR PROFITS (IN EACH CASE WHETHER
              DIRECT OR INDIRECT), EVEN IF THE PARTY KNEW OR SHOULD HAVE KNOWN
              THAT SUCH DAMAGES WERE POSSIBLE. TO THE FULLEST EXTENT PERMITTED
              BY LAW, MY PAY VIEW'S AGGREGATE LIABILITY IN CONNECTION WITH THESE
              TERMS WILL NOT EXCEED ONE HUNDRED DOLLARS (US$100.00). THE PARTIES
              AGREE THAT THE WAIVERS AND LIMITATIONS SPECIFIED IN THIS PARAGRAPH
              APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT
              (INCLUDING NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE AND WILL
              SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY SPECIFIED IN THESE
              TERMS ARE FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.
              <br />
              3. MISCELLANEOUS. If any provision of this Agreement is held to be
              invalid, void or unenforceable, such provision shall be deemed to
              be restated to reflect as nearly as possible the original
              intentions of the parties in accordance with applicable law, and
              the remaining provisions of this Agreement shall remain in full
              force and effect. This Agreement and all claims related to it, its
              execution or the performance of the parties under it, shall be
              construed and governed in all respects according to the laws of
              the State of New York, without regard to the conflict of law
              provisions thereof. This Agreement shall constitute the complete
              agreement between the parties and supersedes all previous
              agreements or representations, written or oral, with respect to
              the subject matter hereof. This Agreement may not be modified or
              amended except in a writing signed by a duly authorized My Pay
              View officer.
            </Typography>
          </div>
        </div>

        <div
          className={classes.cancelLink}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "30px",
          }}
        >
          <div>
            <Checkbox
              style={{ float: "left" }}
              className={classes.optionalInfoCheckboxText}
              color="default"
              checked={props.acceptedEula}
              onChange={props.handleEulaClick}
              name="eula"
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
            />{" "}
            <p style={{ float: "left", margin: "0", lineHeight: "42px" }}>
              I accept these terms.
            </p>
          </div>

          <Button
            variant="contained"
            className={classes.agreeButton}
            disabled={!props.acceptedEula}
            onClick={() => props.handleEulaNext()}
          >
            Next
          </Button>
        </div>
      </div>
      <div className={classes.backButton}>
        <Button className={classes.backButton} onClick={() => props.handleEulaBack()}>
        {"<"} Back  
        </Button>
      </div>
    </div>
  );
};

export default EULA;
const default_state = {
    loggedIn: false,
    token: null,
    dashboardData: null,
    dashboardDataLoading: false
}

const rootReducer = (state = default_state, action) => {
    switch(action.type) {
        case 'DASHBOARD_DATA_LOADING':
            return {
                ...state,
                dashboardDataLoading: true,
            };
        case 'DASHBOARD_DATA_LOADED':
            return {
                ...state,
                dashboardDataLoading: false,
                dashboardData: action.data
            };
        case 'DASHBOARD_DATA_FAIL':
            return {
                ...state,
                dashboardDataLoading: false,
                dashboardError: true,
                dashboardErrorMsg: action.error
            };
        case 'DASHBOARD_DATA_CLEAR':
            return {
                ...state,
                dashboardDataLoading: false,
                dashboardError: false,
                dashboardErrorMsg: null,
            };
        case 'GUEST_DATA_LOADING':
            return {
                ...state,
                guestDataLoading: true,
            };
        case 'GUEST_DATA_LOADED':
            console.log('GUEST_DATA_LOADED')
            console.log(action.data)
            return {
                ...state,
                guestDataLoading: false,
                guestData: action.data,
                guestPageLoading: false,
            };
        case 'GUEST_DATA_FAIL':
            return {
                ...state,
                guestDataLoading: false,
                guestError: true,
                guestErrorMsg: action.error,
                guestPageLoading: false,
            };
        case 'GUEST_DATA_CLEAR':
            return {
                ...state,
                guestDataLoading: false,
                guestError: false,
                guestErrorMsg: null,
            };
        case 'ADD_TOKEN':
            return {
                ...state,
                token: action.token,
                loggedIn: true
            };
        case 'SEND_REPORT_FAIL':
            console.log('SEND_REPORT_FAIL' + action.error)
            return {
                ...state,
                errorSendingReport: true,
                errorMsg: action.error
            };
        case 'SEND_REPORT_DONE':
            console.log('SEND_REPORT_DONE')
            return {
                ...state,
                reportSent: true,
            };
        case 'SEND_REPORT_CLEAR':
            console.log('SEND_REPORT_CLEAR')
            return {
                ...state,
                errorSendingReport: false,
                errorSendingGuestReport: false,
                reportSent: false,
                guestReportSent: false,
                errorMsg: null
            };
        case 'SEND_GUEST_REPORT_FAIL':
            console.log('SEND_GUEST_REPORT_FAIL' + action.error)
            return {
                ...state,
                errorSendingGuestReport: true,
                errorMsg: action.error
            };
        case 'SEND_GUEST_REPORT_DONE':
            console.log('SEND_GUEST_REPORT_DONE')
            return {
                ...state,
                guestReportSent: true,
            };
        case 'BUILD_REPORT_REQUEST_DONE':
            console.log('BUILD_REPORT_REQUEST_DONE')
            return {
                ...state,
                apiReportRequestSuccess: true,
                apiReportRequestFail: false,
                guestPageLoading: false,
            };
        case 'BUILD_REPORT_REQUEST_FAIL':
            console.log('BUILD_REPORT_REQUEST_FAIL')
            return {
                ...state,
                apiReportRequestFail: true,
                guestPageLoading: false,
            };
        case 'BUILD_GUEST_REPORT_REQUEST_DONE':
            console.log('BUILD_GUEST_REPORT_REQUEST_DONE')
            return {
                ...state,
                apiReportRequestSuccess: true,
                guestPageLoading: false,
                apiReportRequestFail: false
            };
        case 'BUILD_GUEST_REPORT_REQUEST_FAIL':
            console.log('BUILD_GUEST_REPORT_REQUEST_FAIL')
            console.log(action.data)
            return {
                ...state,
                guestPageLoading: false,
                apiReportRequestFail: true,
            };
        default: 
            return state;
    }
}

export default rootReducer;
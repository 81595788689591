import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
} from "@material-ui/core";

const withStyles = makeStyles(() => ({
  landingHeaderWrapper: {
    display: "flex",
    justifyContent: "center",
    "& h2": {
      marginLeft: "12px !important",
      margin: "0",
    },
  },
  payviewLogo: {
    height: "35px",
  },
  paymentSuccess: {
    marginTop: "24px",
    "& p": {
      fontSize: "28px",
      fontWeight: "300",
      letterSpacing: "0.25",
    },
  },
  cardBlock: {
    backgroundColor: "#fff",
    boxShadow: "0px 6px 22px 0px RGBA(91, 91, 91, 0.5)",
    borderRadius: "20px",
    padding: "24px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  accountBlock: {
    "& h2": {
      fontSize: "18px",
      fontWeight: "700",
      color: "#32A19F",
      textAlign: "center",
      margin: "0 0 12px 0 !important",
    },
    "& p": {
      color: "#313131",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  accountArrow: {
    margin:"auto",
    "& p": {
      color: "#32A19F",
      fontSize: "32px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
}));

const StripeComplete = (props) => {
  const classes = withStyles();

  useEffect(async () => {
    props.success === "true" ? 
      window.location.href = '/myaccount?success=true' :
      window.location.href = '/myaccount?success=false';
  }, []);

  return (
    <div style={{ padding: "24px" }}>
      <div className={classes.landingHeaderWrapper}>
      </div>
    </div>
  );
};

export default StripeComplete;

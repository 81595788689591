import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Typography, Button, Dialog, DialogContent, CircularProgress } from "@material-ui/core";
import { usePlaidLink } from "react-plaid-link";
import { makeStyles } from "@material-ui/core/styles";
import {
  addGuestLinkedAccount,
  updateNameAndAddress,
  buildGuestReportRequest
} from "../actions/addLinkedAccounts.js";
import EULA from "./EULA.js";
import GuestReportChecklist from "./GuestReportChecklist.js";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { Alert, AlertTitle } from '@material-ui/lab';

const withStyles = makeStyles(() => ({
  welcomeWrapper: {
    marginTop: "50px",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  welcomeHeader: {
    fontSize: "2.0rem",
    marginBottom: "20px",
  },
  eulaWrapper: {
    maxHeight: "55vh",
    overflowY: "scroll",
    marginBottom: "2vh",
    padding: "10px",
  },
  eulaHeader: {
    fontSize: "1.25rem",
    marginBottom: "15px",
    fontWeight: 600,
  },
  newAccountHeaderWrapper: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
    marginBottom: "8%",
    marginTop: "2%",
  },
  newAccountRootWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  payviewLogo: {
    height: "45px",
    margin: "auto 10px auto 10px",
  },
  headerText: {
    fontWeight: 300,
    fontSize: "3rem",
  },
  cancelLink: {
    textDecoration: "none",
    margin: "24px auto 0 auto",
  },
  cancelButton: {
    textTransform: "none",
    background: "rgba(234,234,234,0.25)",
    borderRadius: "10px",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  continueButton: {
    textTransform: "none",
    background: "none",
    boxShadow: "none",
    color: "white",
    "& span": {
      fontSize: "0.75rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  continueButtonText: {
    fontSize: "1.25rem",
  },
  continueButtonWrapper: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: 0,
    marginBottom: "50px",
  },

  plaidButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "160px",
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
  },
  plaidLogo: {
    height: "70px",
    margin: "auto",
    marginBottom: "10px",
  },
  plaidSubtext: {
    color: "#484848",
    fontSize: "0.75rem",
    marginBottom: "20px",
  },
  accountDetailsForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "25px",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  confirmAccountText: {
    fontSize: "1.25rem",
  },
  textField: {
    margin: "10px 0px 10px 0px",
  },
  notchedOutline: {
    borderColor: "white !important",
  },
  notchedOutlinePhone: {
    backgroundColor: "white",
  },
  select: {
    color: "white",
    borderColor: "white",
    marginBottom: "5px",
    width: "100%",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    "&:focus": {
      color: "white",
      borderColor: "white",
    },
    textAlign: "left",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "white",
    },
    "&:focus .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "white",
    },
  },
  icon: { color: "white" },
  label: { color: "white" },
  "& .MuiInputBase-input": {
    border: "1px solid #fff",
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderColor: "#fff",
    },
  },
  dropdownLabel: {
    textAlign: "left",
    color: "white",
    marginBottom: "5px",
  },
  "& .MuiDialogContent-root": {
    background: "#888",
    color: "white",
  },
  spinner: {
    marginRight: "20px",
  },
  dialogText: {
    fontSize: "1.15rem",
  },
  optionalInfoCheckboxText: {
    color: "#fff",
  },
  optionalInfoCheckbox: {
    color: "#313131",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  optionalInfoCheckboxColor: {
    color: "#32A19F",
  },
  alertBar: {
    marginBottom: "15px",
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: "white",
    paddingBottom: "20px",
  },
  spinner: {
    marginRight: "20px",
  },
  dialogText: {
    fontSize: "1.15rem",
  },
  dialogButton: {
    color: "white",
  },
}));

const GuestReport = (props) => {
  const classes = withStyles();
  const [linkToken, setLinkToken] = useState(null);
  const [newAccountStep, setNewAccountStep] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [acceptedEula, setAcceptedEula] = useState(false);
  const [showChecklist, setShowChecklist] = useState(true);
  const [plaidLinked, setPlaidLinked] = useState(false);
  const [reportNotFound, setReportNotFound] = useState(false);
  const [reportInProgress, setReportInProgress] = useState(false);
  const [reportComplete, setReportComplete] = useState(false);
  const [reportRequestError, setReportRequestError] = useState(false);

  const { apiReportRequestSuccess, apiReportRequestFail, guestData, guestDataLoading} = useSelector((state) => state);

  const { id, pin } = useParams();

  const onSuccess = useCallback(
    (token, metadata) => {
      console.log(metadata);

      dispatch(addGuestLinkedAccount(token, metadata, id, pin));
      setPlaidLinked(true);
      handleReportNext()
      setShowChecklist(true);
    },
    [dispatch]
  );

  useEffect(async () => {
    try {
      const params = {
        request_id: id,
        request_pin: pin,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/guest/create_link_token`,
        params,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(`guest link token: ${response.data.link_token}`);
      setLinkToken(response.data.link_token);
    } catch (error) {
      // todo --- undo this for testing real report
      if(error && error.response && error.response.status == 500 && error.response.data.startsWith("DoesNotExist")) {
        console.log("Requested report not found.")
        setReportNotFound(true);
      }
    }
  }, [setLinkToken]);

  useEffect(() => {
    if(apiReportRequestSuccess) {
      setReportInProgress(true);
      setReportRequestError(false);
    } 
  }, [apiReportRequestSuccess]);

  useEffect(() => {
    if(apiReportRequestFail) {
      setReportRequestError(true);
    } 
  }, [apiReportRequestFail]);

  useEffect(() => {
    console.log('guestdata', guestData)
    if(guestData && guestData.proof_amount) {
      buildReport();
    }
  },[guestData])

  const config = {
    token: linkToken !== null ? linkToken : "",
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  const handlePlaidClick = () => {
    if (ready) {
      open();
    }
  };

  const handleCancelClick = () => {
    setShowChecklist(true);
    // setNewAccountStep(0);
  };

  const handleEulaClick = (event) => {
    setAcceptedEula(event.target.checked);
  };

  const handleEulaNext = (event) => {
    setNewAccountStep((step) => step + 1);
    setShowChecklist(true);
  };
  const handleEulaBack = (event) => {
    setShowChecklist(true);
  }

  const handleGoToEula = () => {
    setShowChecklist(false);
    if(newAccountStep !== 0) {
      setNewAccountStep(0);
    }
  };

  const handleGoToPlaidSetup = () => {
    if(acceptedEula && newAccountStep === 0) {
      // accepted then clicked back
      setNewAccountStep((step) => step + 1);
      setShowChecklist(false);
    }
    setShowChecklist(false);
  };

  const handleGoToCompleteReport = () => {
    setShowChecklist(false);
    // todo update to go to report view page
    history.push(`/sendreport/guest/${id}/${pin}`);
  };

  const handleReportNext = (event) => {
    setNewAccountStep((step) => step + 1);
    setShowChecklist(true);
  };

  const buildReport = useCallback(() => {
    console.log("report building in progress")

    let reportData = {
      request_id: id,
      request_pin: pin
    }
    dispatch(buildGuestReportRequest(reportData));
  }, [dispatch]);

  const renderStep = (step) => {
    if (step === 0 && !showChecklist) {
      return (
        <>
          <EULA
            acceptedEula={acceptedEula}
            handleEulaClick={handleEulaClick}
            handleEulaNext={handleEulaNext}
            handleEulaBack={handleEulaBack}
            cancelLink={`/guest/${id}/${pin}`}
          />
        </>
      );
    } else if (step === 1 && !showChecklist) {
      return (
        <>
          <div className={classes.welcomeWrapper}>
            <Typography className={classes.welcomeHeader}>Welcome!</Typography>
            <Typography>
              Link the first account you'd like to use for your report:
            </Typography>
          </div>
          <div
            onClick={() => handlePlaidClick()}
            className={classes.plaidButtonWrapper}
          >
            <img
              className={classes.plaidLogo}
              src="/icons/plaid_logo_black.svg"
            />
            <Typography className={classes.plaidSubtext}>
              Secure Login with Plaid{" "}
            </Typography>
          </div>
          <div
            className={classes.cancelLink}
            onClick={() => handleCancelClick()}
          >
            <Button variant="contained" className={classes.cancelButton}>
              Cancel
            </Button>
          </div>
        </>
      );
    } else if (showChecklist) {
      return (
        <>
          {reportNotFound &&
              <Alert severity="error" className={classes.alertBar}>
                  <AlertTitle>{"Requested report not found."}</AlertTitle>
              </Alert>
          }
          {reportRequestError &&
              <Alert severity="error" className={classes.alertBar}>
                  <AlertTitle>{"Unable to start report request at this time."}</AlertTitle>
              </Alert>
          }
          <GuestReportChecklist
            handleGoToEula={handleGoToEula}
            handleGoToPlaidSetup={handleGoToPlaidSetup}
            handleGoToCompleteReport={handleGoToCompleteReport}
            handleReportNext={handleReportNext}
            eulaComplete={true}
            plaidComplete={acceptedEula}
            plaidLinked={plaidLinked}
            reportInProgress={reportInProgress}
            reportComplete={reportComplete}
            reportNotFound={reportNotFound}
          />
        </>
      );
    }
  };

  return (
    <div>
      <div className={classes.newAccountRootWrapper}>
        <div className={classes.newAccountHeaderWrapper}>
          <img className={classes.payviewLogo} src="/PayView_Icon.png" />
          {/* <h2 className={classes.headerText}>PayView</h2> */}
        </div>
        {renderStep(newAccountStep)}
      </div>
      <Dialog
          open={guestDataLoading}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogContent className={classes.loadingDialog} style={{display: 'flex', justifyContent: 'center'}}>
              <Typography variant='h6' className={classes.dialogText} style={{display: 'flex', justifyContent: 'center'}}>
                  <CircularProgress className={classes.spinner} color="inherit" />
                    Loading...
              </Typography>
          </DialogContent>
      </Dialog>
    </div>
  );
};

export default connect((state) => ({
  dashboardData: state.dashboardData,
  guestDataLoading: state.guestDataLoading,
  apiReportRequestFail: state.apiReportRequestFail,
  apiReportRequestSuccess: state.apiReportRequestSuccess
}))(GuestReport);
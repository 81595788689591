import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import { usePlaidLink } from "react-plaid-link";
import { makeStyles } from "@material-ui/core/styles";
import {
  addLinkedAccount,
  updateNameAndAddress,
} from "../actions/addLinkedAccounts.js";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import EULA from "./EULA.js";

const withStyles = makeStyles(() => ({
  welcomeWrapper: {
    marginTop: "50px",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  welcomeHeader: {
    fontSize: "2.0rem",
    marginBottom: "20px",
  },
  eulaWrapper: {
    maxHeight: "55vh",
    overflowY: "scroll",
    marginBottom: "2vh",
    padding: "10px",
  },
  eulaHeader: {
    fontSize: "1.25rem",
    marginBottom: "15px",
    fontWeight: 600,
  },
  newAccountHeaderWrapper: {
    display: "flex",
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  newAccountRootWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "80vh",
  },
  payviewLogo: {
    height: "45px",
    margin: "auto 10px auto 10px",
  },
  headerText: {
    fontWeight: 300,
    fontSize: "3rem",
  },
  cancelLink: {
    textDecoration: "none",
    margin: "auto",
  },
  cancelButton: {
    textTransform: "none",
    background: "rgba(234,234,234,0.25)",
    borderRadius: "10px",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  continueButton: {
    textTransform: "none",
    background: "none",
    boxShadow: "none",
    color: "white",
    "& span": {
      fontSize: "0.75rem",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  continueButtonText: {
    fontSize: "1.25rem",
  },
  continueButtonWrapper: {
    display: "flex",
    position: "absolute",
    bottom: 0,
    right: 0,
    marginBottom: "50px",
  },

  plaidButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    height: "160px",
    width: "50%",
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "white",
    borderRadius: "5px",
    marginTop: "10px",
    cursor: "pointer",
  },
  plaidLogo: {
    height: "70px",
    margin: "auto",
    marginBottom: "10px",
  },
  plaidSubtext: {
    color: "#484848",
    fontSize: "0.75rem",
    marginBottom: "20px",
  },
  accountDetailsForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "25px",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  confirmAccountText: {
    fontSize: "1.25rem",
  },
  textField: {
    margin: "10px 0px 10px 0px",
  },
  notchedOutline: {
    borderColor: "white !important",
  },
  notchedOutlinePhone: {
    backgroundColor: "white",
  },
  select: {
    color: "white",
    borderColor: "white",
    marginBottom: "5px",
    width: "100%",
    "&:before": {
      borderColor: "white",
    },
    "&:after": {
      borderColor: "white",
    },
    "&:not(.Mui-disabled):hover::before": {
      borderColor: "white",
    },
    "&:focus": {
      color: "white",
      borderColor: "white",
    },
    textAlign: "left",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "white",
    },
    "&:focus .MuiOutlinedInput-input": {
      color: "white",
      borderColor: "white",
    },
  },
  icon: { color: "white" },
  label: { color: "white" },
  "& .MuiInputBase-input": {
    border: "1px solid #fff",
    padding: "10px 26px 10px 12px",
    "&:focus": {
      borderColor: "#fff",
    },
  },
  dropdownLabel: {
    textAlign: "left",
    color: "white",
    marginBottom: "5px",
  },
  "& .MuiDialogContent-root": {
    background: "#888",
    color: "white",
  },
  spinner: {
    marginRight: "20px",
  },
  dialogText: {
    fontSize: "1.15rem",
  },
  optionalInfoCheckboxText: {
    color: "#fff",
  },
  optionalInfoCheckbox: {
    color: "#313131",
    "& span": {
      fontSize: "0.75rem",
    },
  },
  optionalInfoCheckboxColor: {
    color: "#32A19F",
  },
  loadingDialog: {
    backgroundColor: "#000",
    opacity: ".7",
    color: "white",
    paddingBottom: "20px",
  },
  spinner: {
    marginRight: "20px",
  },
  dialogText: {
    fontSize: "1.15rem",
  },
  dialogButton: {
    color: "white",
  },
}));

const SignUpNewAccount = (props) => {
  const classes = withStyles();
  const [linkToken, setLinkToken] = useState(null);
  const [newAccountStep, setNewAccountStep] = useState(0);
  const dispatch = props.dispatch;
  const history = useHistory();
  const dashboardData = props.dashboardData;
  const [selectedName, setSelectedName] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [acceptedEula, setAcceptedEula] = useState(false);

  const onSuccess = useCallback(
    (token, metadata) => {
      dispatch(addLinkedAccount(token, metadata));
      setNewAccountStep((step) => step + 1);
    },
    [dispatch]
  );

  useEffect(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PAYVIEW_BASE_URL}/api/create_link_token`
      );
      console.log(response);
      console.log(`link token: ${response.data.link_token}`);
      setLinkToken(response.data.link_token);
    } catch (error) {
      console.log(error);
    }
  }, [setLinkToken]);

  const config = {
    token: linkToken !== null ? linkToken : "",
    onSuccess,
  };

  const { open, ready, err } = usePlaidLink(config);

  const handleClick = () => {
    if (ready) {
      open();
    }
  };

  const handleNameSelectChange = (event) => {
    setSelectedName(event.target.value);
  };

  const handleAddressSelectChange = (event) => {
    console.log(event.target.value);
    setSelectedAddress(event.target.value);
  };

  const handlePhoneSelectChange = (event) => {
    setSelectedPhone(event.target.value);
  };

  const continueClicked = (event) => {
    const addressString = `${selectedAddress.data.street} ${selectedAddress.data.city}, ${selectedAddress.data.region} ${selectedAddress.data.postal_code}`;
    const lname = selectedName.substring(selectedName.lastIndexOf(" "));
    const fname = selectedName.substring(0, selectedName.lastIndexOf(" "));
    dispatch(updateNameAndAddress(fname, lname, addressString));
  };

  const handleEulaClick = (event) => {
    setAcceptedEula(event.target.checked);
  };

  const handleEulaNext = (event) => {
    setNewAccountStep((step) => step + 1);
  };

  const renderStep = (step) => {
    if (step === 0) {
      return (
        <>
          <EULA
            acceptedEula={acceptedEula}
            handleEulaClick={handleEulaClick}
            handleEulaNext={handleEulaNext}
          />
        </>
      );
    } else if (step === 1) {
      return (
        <>
          <div className={classes.welcomeWrapper}>
            <Typography className={classes.welcomeHeader}>Welcome!</Typography>
            <Typography>Link your first account:</Typography>
          </div>
          <div
            onClick={() => handleClick()}
            className={classes.plaidButtonWrapper}
          >
            <img
              className={classes.plaidLogo}
              src="/icons/plaid_logo_black.svg"
            />
            <Typography className={classes.plaidSubtext}>
              Secure Login with Plaid{" "}
            </Typography>
          </div>
          <Link to="/" className={classes.cancelLink}>
            <Button variant="contained" className={classes.cancelButton}>
              Cancel
            </Button>
          </Link>
        </>
      );
    } else if (step === 2) {
      const names = [];
      const addresses = [];
      const phoneNums = [];

      if (dashboardData && dashboardData.linkedAccounts && dashboardData.linkedAccounts.length > 1) {
        dashboardData.linkedAccounts[0].accounts.forEach((acct) => {
          acct.owners.forEach((owner) => {
            owner.names.forEach((name) => {
              if (!names.includes(name)) {
                names.push(name);

                if (!selectedName) {
                  setSelectedName(name);
                }
              }
            });
            owner.addresses.forEach((address) => {
              if (
                !addresses.find((x) => x.data.street === address.data.street)
              ) {
                addresses.push(address);
                if (!selectedAddress) {
                  setSelectedAddress(address);
                }
              }
            });
            owner.phone_numbers.forEach((num) => {
              if (!phoneNums.find((x) => x.data === num.data)) {
                phoneNums.push(num);
                if (!selectedPhone) {
                  setSelectedPhone(num);
                }
              }
            });
          });
        });
      }

      return (
        <>
          <Dialog
            open={!dashboardData}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              className={classes.loadingDialog}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                variant="h6"
                className={classes.dialogText}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <CircularProgress className={classes.spinner} color="inherit" />
                Gathering your information...
              </Typography>
            </DialogContent>
          </Dialog>

          <Typography className={classes.confirmAccountText}>
            Confirm Account Details:
          </Typography>

          <form className={classes.accountDetailsForm}>
            <InputLabel id="-name-label" className={classes.dropdownLabel}>
              Name
            </InputLabel>
            <Select
              id="name-select"
              value={selectedName}
              label="Name"
              variant="outlined"
              className={classes.select}
              fullWidth={true}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  select: classes.select,
                },
              }}
              onChange={handleNameSelectChange}
            >
              {names.map((name) => {
                return (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>

            <InputLabel id="address-label" className={classes.dropdownLabel}>
              Address
            </InputLabel>
            <Select
              id="address-select"
              value={selectedAddress}
              label="Address"
              variant="outlined"
              className={classes.select}
              fullWidth={true}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  select: classes.select,
                },
              }}
              onChange={handleAddressSelectChange}
            >
              {addresses.map((add) => {
                return (
                  <MenuItem key={add.data.street} value={add}>
                    {`${add.data.street} ${add.data.city}, ${add.data.region} ${add.data.postal_code}`}
                  </MenuItem>
                );
              })}
            </Select>

            <InputLabel id="phone-label" className={classes.dropdownLabel}>
              Phone
            </InputLabel>
            <Select
              id="phone-select"
              value={selectedPhone}
              label="Phone"
              variant="outlined"
              className={classes.select}
              fullWidth={true}
              inputProps={{
                classes: {
                  icon: classes.icon,
                  select: classes.select,
                },
              }}
              onChange={handlePhoneSelectChange}
            >
              {phoneNums.map((num) => {
                return (
                  <MenuItem key={num.data} value={num}>
                    {num.data}
                  </MenuItem>
                );
              })}
            </Select>
          </form>
          <div className={classes.continueButtonWrapper}>
            <Button
              variant="contained"
              className={classes.continueButton}
              onClick={continueClicked}
              disabled={!selectedName || !selectedAddress}
            >
              <Typography className={classes.continueButtonText}>
                Continue
              </Typography>
              <img src="icons/Button/Next_White_On.png" />
            </Button>
          </div>
        </>
      );
    }
  };

  return (
    <div>
      <div className={classes.newAccountRootWrapper}>
        <div className={classes.newAccountHeaderWrapper}>
          <img className={classes.payviewLogo} src="/PayView_Icon.png" />
          <h2 className={classes.headerText}>PayView</h2>
        </div>
        {renderStep(newAccountStep)}
      </div>
    </div>
  );
};

export default connect((state) => ({
  dashboardData: state.dashboardData,
  dashboardDataLoading: state.dashboardDataLoading,
}))(SignUpNewAccount);
import React, { useState, useEffect } from 'react'
import Amplify, { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles'
import { Button, Card } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook';

const withStyles = makeStyles(() => ({
    googleSignInButton: {
        backgroundColor: "white",
        textTransform: "none",
        margin: "20px 0px 10px 0"
    },
    googleLogo: {
        width: "20px",
        marginRight: "7px"
    },
    facebookSignInButton: {
        backgroundColor: "#385499",
        textTransform: "none",
        color: "white",
        margin: "0px 0px 10px 0"
    },
    signinButtonWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    facebookSignInButton: {
        backgroundColor: "#385499",
        textTransform: "none",
        color: "white",
        margin: "0px 0px 10px 0"
    },
    facebookLogo: {
        width: "20px",
        marginRight: "7px"
    },
    formInput: {
        fontSize: "0.875rem",
        height: "25px"
    },
    formCard: {
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        width: "250px",
    },
    formCardCentered: {
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        // width: "250px",
        // alignItems: "center"
    },
    signUpButton: {
        textTransform: "none",
        margin: "10px 0 10px 0",
        backgroundColor: "#0077cc",
        color: "white",
    },
    resendButton: {
        textTransform: "none",
        margin: "10px 0 10px 0",
    },
    alertBar: {
        marginBottom: "15px"
    }
}));


Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_AWS_REGION, // (required) - Region where Amazon Cognito project was created   
    aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID, // (optional) -  Amazon Cognito User Pool ID   
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in  
})


const SignUpForm = () => {
    const history = useHistory();
    const classes = withStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmationCode, setConfirmationCode] = useState('');
    const [verified, setVerified] = useState(false);
    const [signupError, setSignupError] = useState(null);
    const googleClientId = '976404741826-i5pes84o9smik6re8d2bpsfc3ob9h8eg.apps.googleusercontent.com'

    const signUp = async (event) => {
        event.preventDefault();
        try {
            const { user } = await Auth.signUp({
                username,
                password,
                attributes: {
                    email
                }
            });
            console.log(user);
            setPassword('')
            setEmail('')
            setVerified(true)
        } catch (error) {
            console.log('error signing up:', error);
            setSignupError(error);
        }
    }

    const confirmSignUp = async (confirmationCode) => {
        console.log(confirmationCode)
        try {
            await Auth.confirmSignUp(username, confirmationCode);
            console.log("new account confirm sign up successful")
            history.push("/newusersignin")
        } catch (error) {
            console.log('error confirming sign up', error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (verified) {
            confirmSignUp(confirmationCode)
            setConfirmationCode('')
            setUsername('')
        } else {
            signUp(event)
            // setPassword('')
            // setEmail('')
            // setVerified(true)
        }
        event.target.reset();
    }

    const resendConfirmationCode = async () => {
        try {
            await Auth.resendSignUp(username);
            console.log('code resent successfully');
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }
    
    const signUpGoogle = () => {
        try {
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://dev.mypayview.com/hosteduiredirectnewuser/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p&new_user=true'
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=http://localhost:3000/hosteduiredirectnewuser/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            window.location.href = process.env.REACT_APP_GOOGLE_NEW_USER_URL;
        }
        catch (error) {
            console.log(error)
        }
    }
    const signUpFacebook = () => {
        try {
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://dev.mypayview.com/hosteduiredirectnewuser/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=http://localhost:3000/hosteduiredirectnewuser/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            window.location.href = process.env.REACT_APP_FACEBOOK_NEW_USER_URL;
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {verified ? (
                <>
                    <h2>Please check your email for Verification Code</h2>
                    <Card>
                        <form className={classes.formCardCentered} onSubmit={handleSubmit}>
                            Verification Code
                    <input
                                value={confirmationCode}
                                onChange={event => setConfirmationCode(event.target.value)}
                                className={classes.formInput}
                            >
                            </input>

                            <br />

                            <Button className={classes.signUpButton} variant="contained" type="submit">Confirm Sign Up</Button>

                            <p>Didn't receive an email?</p>
                            <Button className={classes.resendButton} variant="contained" onClick={() => resendConfirmationCode()}>Resend Verification Code</Button>
                        </form>
                    </Card>
                </>
            ) : (
                <>
                    <h2>Sign Up</h2>
                    {signupError &&
                        <Alert severity="error" className={classes.alertBar}>
                            <AlertTitle>{signupError.message}</AlertTitle>
                        </Alert>
                    }
                    <Card>
                        <form className={classes.formCard} onSubmit={handleSubmit}>
                            Email
                            <input
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                                className={classes.formInput}
                            >
                            </input>
                            Username
                            <input
                                value={username}
                                onChange={event => setUsername(event.target.value)}
                                className={classes.formInput}

                            >
                            </input>
                            Password
                            <input
                                type="password"
                                value={password}
                                onChange={event => setPassword(event.target.value)}
                                className={classes.formInput}
                                minLength="12"
                            >
                            </input>
                            <br />
                            <Button className={classes.signUpButton} variant="contained" type="submit">Sign Up</Button>
                        </form>
                    </Card>
                    <div className={classes.signinButtonWrapper}>
                        <Button
                            className={classes.googleSignInButton}
                            onClick={signUpGoogle}
                            variant="contained"

                        >
                            <img className={classes.googleLogo} src="/google_logo.png" />
                            Sign up with Google
                        </Button>
                        <Button
                            className={classes.facebookSignInButton}
                            onClick={signUpFacebook}
                            variant="contained"
                        >
                            <FacebookIcon className={classes.facebookLogo} />
                            Sign up with Facebook
                        </Button>
                    </div>
                </>
            )}
        </div>
    )
}

export default SignUpForm;

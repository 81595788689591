import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { combineReducers } from 'redux'
import rootReducer from './reducers/authTokenReducer';
import linkedAccountReducer from './reducers/linkedAccountsReducer';
import { configureStore } from '@reduxjs/toolkit'
import {addToken} from './actions/addToken';

export default configureStore({
  reducer: {rootReducer},
})

// const rootReducer = combineReducers({
//   authTokenReducer,
//   linkedAccountReducer
// })

const store = configureStore({reducer:rootReducer})

const persistedToken = localStorage.getItem('token');

if(persistedToken){
  store.dispatch(addToken(persistedToken))
}
  

store.subscribe(() => {
  localStorage.setItem('token', store.getState().token)
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);


import "./App.css";
import SignUpForm from "./components/SignUpForm";
import React, { useEffect } from "react";
import Landing from "./components/Landing";
import Dashboard from "./components/Dashboard";
import SignInForm from "./components/SignInForm";
import Communications from "./components/communications/Communications";
import ProofFund from "./components/ProofFund";
import MyAccount from "./components/MyAccount";
import SendReport from "./components/communications/SendReport";
import RequestReport from "./components/communications/RequestReport";
import PrivacyPolicy from "./components/PrivacyPolicy";
import HostedUiRedirect from "./components/HostedUiRedirect";
import HostedUiRedirectNewUser from "./components/HostedUiRedirectNewUser";
import SignUpNewAccount from "./components/SignUpNewAccount";
import GuestReport from "./components/GuestReport";
import NewUserSignIn from "./components/NewUserSign";
import ProcessComplete from "./components/ProcessComplete";
import RequestComplete from "./components/RequestComplete";
import StripeComplete from "./components/StripeComplete";

import { Switch, Route, Link, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const withStyles = makeStyles(() => ({
  App: {
    textAlign: "center",
  },
  App_logo: {
    height: "40vmin",
    pointerEvents: "none",
  },
  App_root: {
    background: "#20aa61",
    background: "linear-gradient(-9deg, #46a7ba 0%, #1faa60 100%)",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
  },
}));

function App() {
  const token = useSelector((state) => state.token);
  const classes = withStyles();

  //refactor to have whitelisted routes rather than this conditional wrapped in a useEffect
  useEffect(() => {
    if (
      !token &&
      window.location.pathname !== "/" &&
      window.location.pathname !== "/policy" &&
      window.location.pathname !== "/newusersignin" &&
      !window.location.pathname.startsWith("/guest")
    ) {
      window.location.href = "/";
    }
  }, [token]);

  return (
    <div className={classes.App}>
      <div className={classes.App_root}>
        <BrowserRouter>
          <Switch>
            <Route
              path="/dashboard"
              render={() => <Dashboard title="Dashboard" />}
            />
            <Route path="/communications" component={Communications} />
            <Route path="/prooffund" component={ProofFund} />
            <Route path="/myaccount" component={MyAccount} />
            <Route exact path="/" component={Landing} />
            <Route path="/signin" component={SignInForm} />
            <Route path="/policy" component={PrivacyPolicy} />
            <Route path="/signup" component={SignUpForm} />
            <Route path="/newaccount" component={SignUpNewAccount} />
            <Route path="/newusersignin" component={NewUserSignIn} />
            <Route path="/hosteduiredirect" component={HostedUiRedirect} />
            <Route
              path="/hosteduiredirectnewuser"
              component={HostedUiRedirectNewUser}
            />
            <Route path="/guest/:id/:pin" component={GuestReport} />
            <Route
              path="/sendreport/guest/:id/:pin"
              render={() => <SendReport title="Communications" guest="true" />}
            />
            <Route
              path="/sendreport"
              render={() => <SendReport title="Communications" />}
            />
            <Route
              path="/requestreport"
              render={() => <RequestReport title="Communications" />}
            />

            <Route path="/processcomplete" component={ProcessComplete} />
            <Route path="/requestcomplete" component={RequestComplete} />
            <Route 
              path="/stripe_success"
              render={() => <StripeComplete success="true" />}
            />
            <Route 
              path="/stripe_error"
              render={() => <StripeComplete success="false" />}
            />
          </Switch>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
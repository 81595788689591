import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'

const withStyles = makeStyles(() => ({
    privacyLink: {
        color: "black",
        textDecoration: "none",
        fontSize: "0.75rem"
    },
    footerRoot: {
        backgroundColor: "white"
    }
}))

const Footer = props => {
    const classes = withStyles();
    return (
        <div className={classes.footerRoot}>
            <Link className={classes.privacyLink} to="/policy">Privacy Policy</Link>
        </div>
    )
}

export default Footer;
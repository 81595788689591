const linkedAccountReducer = (state, action) => {
    switch(action.type) {
        case 'ADD_ACCOUNT':
            return {
                linkedAccounts: action.linkedAccounts,
            };
        //use like a GET for an API
        case 'UPDATE_ACCOUNTS':
            return {
                linkedAccounts: action.linkedAccounts,
            }
        default: 
            return state;
    }
}

export default linkedAccountReducer;
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import Amplify, { Auth, Cache } from 'aws-amplify';
import { connect } from 'react-redux';
import { addToken } from '../actions/addToken'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import FacebookIcon from '@material-ui/icons/Facebook';
import { fetchDashboardData } from '../actions/addLinkedAccounts.js'


const withStyles = makeStyles(() => ({
    googleSignInButton: {
        backgroundColor: "white",
        textTransform: "none",
        margin: "20px 0px 10px 0"
    },
    googleLogo: {
        width: "20px",
        marginRight: "7px"
    },
    facebookSignInButton: {
        backgroundColor: "#385499",
        textTransform: "none",
        color: "white",
        margin: "0px 0px 10px 0"
    },
    facebookLogo: {
        width: "20px",
        marginRight: "7px"
    },
    signinButtonWrapper: {
        display: "flex",
        flexDirection: "column"
    },
    signInButton: {
        textTransform: "none",
        margin: "10px 0 10px 0",
        backgroundColor: "#0077cc",
        color: "white",
    },
    signInText: {
        textAlign: "left",
        fontSize: "1.0rem",
        fontWeight: "600"
    },
    formInput: {
        fontSize: "0.875rem",
        height: "25px"
    },
    formCard: {
        display: "flex",
        flexDirection: "column", 
        padding: "15px",
        width: "250px"
    }
}));

Amplify.configure({
    aws_cognito_region: process.env.REACT_APP_AWS_REGION, // (required) - Region where Amazon Cognito project was created   
    aws_user_pools_id: process.env.REACT_APP_COGNITO_POOL_ID, // (optional) -  Amazon Cognito User Pool ID   
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_POOL_WEB_CLIENT_ID, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
    aws_mandatory_sign_in: "enable", // (optional) - Users are not allowed to get the aws credentials unless they are signed in 
})

const SignInForm = props  => {
    const history = useHistory();
    const classes = withStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const { dashboardData, dashboardDataLoading } = props;
    const dispatch = props.dispatch;


    // useEffect(() => {
    //     dispatch(fetchDashboardData())
    //     console.log(dashboardData)
    // }, [])

    const signIn = async () => {
        try {
            const user = await Auth.signIn(username, password);
            console.log('sign in successful!')
            // console.log(user)
            console.log(`jwt token: ${user.signInUserSession.idToken.jwtToken}`)

            dispatch(addToken(user.signInUserSession.idToken.jwtToken))
            // if (!dashboardData || dashboardData.linkAccounts.length === 0) {
            //     history.push("/newaccount");
            // } else {
                history.push("/dashboard");
            // }
        } catch (error) {
            console.log('error signing in', error);
        }
    }

    const handleSignIn = (event) => {
        event.preventDefault();

        signIn();

        event.target.reset();
    }

    const signInGoogle = () => {
        try {
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=http://localhost:3000/hosteduiredirect/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Google&redirect_uri=https://dev.mypayview.com/hosteduiredirect/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
        }
        catch (error) {
            console.log(error)
        }
    }
    const signInFacebook = () => {
        try {
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=http://localhost:3000/hosteduiredirect/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            // window.location.href = 'https://mypayview-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://dev.mypayview.com/hosteduiredirect/&response_type=TOKEN&client_id=3iilhgiuep8i34qbodvh46ev8p'
            window.location.href = process.env.REACT_APP_FACEBOOK_LOGIN_URL;
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            <h2>Welcome Back</h2>
            <Card>
                <form className={classes.formCard} onSubmit={handleSignIn}>
                    <Typography className={classes.signInText}>Username</Typography>
                    <input
                        className={classes.formInput}
                        value={username}
                        onChange={event => setUsername(event.target.value)}
                    >
                    </input>
                    <Typography className={classes.signInText}>Password</Typography>
                    <input
                        className={classes.formInput}
                        type="password"
                        value={password}
                        onChange={event => setPassword(event.target.value)}
                    >
                    </input>
                    <a style={{ color: "white", fontSize: "12px" }} href="#">Forgot Your Password?</a>
                    <Button className={classes.signInButton} variant="contained" type="submit">Sign In</Button>
                </form>
            </Card>
            <div className={classes.signinButtonWrapper}>
                <Button
                    className={classes.googleSignInButton}
                    onClick={signInGoogle}
                    variant="contained"

                >
                    <img className={classes.googleLogo} src="/google_logo.png" />
                Sign in with Google
            </Button>
                <Button
                    className={classes.facebookSignInButton}
                    onClick={signInFacebook}
                    variant="contained"
                >
                    <FacebookIcon className={classes.facebookLogo} />
                Sign in with Facebook
            </Button>
            </div>
        </div>

    )
}

export default connect((state) => ({
    dashboardData: state.dashboardData,
    dashboardDataLoading: state.dashboardDataLoading
}))(SignInForm);

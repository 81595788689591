import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
} from "@material-ui/core";

const withStyles = makeStyles(() => ({
  landingHeaderWrapper: {
    display: "flex",
    justifyContent: "center",
    "& h2": {
      marginLeft: "12px !important",
      margin: "0",
    },
  },
  payviewLogo: {
    height: "35px",
  },
  paymentSuccess: {
    marginTop: "24px",
    "& p": {
      fontSize: "28px",
      fontWeight: "300",
      letterSpacing: "0.25",
    },
  },
  cardBlock: {
    backgroundColor: "#fff",
    boxShadow: "0px 6px 22px 0px RGBA(91, 91, 91, 0.5)",
    borderRadius: "20px",
    padding: "24px",
    display: "flex",
    alignItems: "center",
    width: "65%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  accountBlock: {
    "& h2": {
      fontSize: "18px",
      fontWeight: "700",
      color: "#32A19F",
      textAlign: "center",
      margin: "0 0 12px 0 !important",
    },
    "& p": {
      color: "#313131",
      fontSize: "14px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
  accountArrow: {
    margin:"auto",
    "& p": {
      color: "#32A19F",
      fontSize: "32px",
      fontWeight: "400",
      textAlign: "center",
    },
  },
}));

const RequestComplete = (props) => {
  const classes = withStyles();

  const continueClick = (event) => {
    window.location.href = '/communications';
  };

  return (
    <div style={{ padding: "24px" }}>
      <div className={classes.landingHeaderWrapper}>
        <img className={classes.payviewLogo} src="/PayView_Icon.png" />
        <h2>PayView</h2>
      </div>
      <div className={classes.paymentSuccess}>
        <svg
          className={classes.itemIcon}
          width="79px"
          height="64px"
          viewBox="0 0 27 22"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Checkmark</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Checkmark" fill="#fff">
              <polygon
                id="Fill-1"
                points="7.3639424 22 0 14.6666667 2.4540864 12.222781 7.3639424 17.1122286 24.5459136 0 27 2.44552"
              ></polygon>
            </g>
          </g>
        </svg>
        <p>Your Request has been sent!</p>
      </div>
      <div className={classes.cardBlock}
        onClick={() => {continueClick()}}>
        <div className={classes.accountBlock}>
        </div>
        <div className={classes.accountArrow}>
          <Typography>{"Continue >"}</Typography>
        </div>
      </div>
    </div>
  );
};

export default RequestComplete;

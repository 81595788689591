import React from 'react'
import { Link } from 'react-router-dom'
import Button from "@material-ui/core/Button"
import Typography from '@material-ui/core/Typography'
import Footer from './Footer'
import Box from "@material-ui/core/Box"
import { makeStyles } from '@material-ui/core/styles'

const withStyles = makeStyles(() => ({
    landingButton: {
        textTransform: "none",
        margin: "10px 5px 10px 5px"
    },
    signUpButton:{
        backgroundColor: "#0077cc",
        color: "white",
        textTransform: "none",
        margin: "10px 5px 10px 5px"
    },
    landingLink: {
        textDecoration: "none"
    },
    landingHeaderWrapper: {
        display: "flex",
        align: "center"
    },
    payviewLogo: {
        height: "35px",
        margin: "auto",
        marginRight: "15px"
    }
}))

const Landing = props => {
    const classes = withStyles();

    return (
        <div>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                >
                <div className={classes.landingHeaderWrapper}>
                    <img className={classes.payviewLogo} src="/PayView_Icon.png" />
                    <h2>PayView</h2>
                </div>
            </Box>
            <Link className={classes.landingLink} to="/signin">
                <Button
                    className={classes.landingButton}
                    variant="contained"
                >Sign In</Button>
            </Link>
            <Link className={classes.landingLink} to="/signup">
                <Button
                    className={classes.signUpButton}
                    variant="contained"
                >Sign Up</Button>
            </Link>
        </div>

    )
}

export default Landing;
import React, { useState } from "react";
import Header from "./Header";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography } from "@material-ui/core";

const withStyles = makeStyles(() => ({
  layoutRoot: {
    marginLeft: "5%",
    marginRight: "5%",
  },
  payviewLogo: {
    height: "34px",
    margin: "15px",
  },
  hamburgerIcon: {
    height: "24px",
    margin: "15px",
  },
  list: {
    width: "200px",
  },
  drawerRoot: {
    background: "#E5E9E9",
  },
  drawerItem: {
    "&:hover": {
      backgroundImage:
        "linear-gradient(-7deg, #46A7BA 0%, #43A7B3 7%, #20AA61 99%, #1FAA60 100%)",
      border: "1px solid #979797",
      color: "#ffffff",
    },
    "&:selected:hover": {
      backgroundImage:
        "linear-gradient(-7deg, #46A7BA 0%, #43A7B3 7%, #20AA61 99%, #1FAA60 100%)",
      border: "1px solid #979797",
      color: "#ffffff",
    },
  },
  drawerLink: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.87)",
  },
  pageTitleWrapper: {
    margin: "15px",
  },
  pageTitle: {
    fontSize: "1.25rem",
  },
  root: {
    width: "50% !important",
  },
  active: {
    backgroundImage: "linear-gradient(to right, #46a7ba, #1faa60) !important",
  },
  payviewLogoText: {
    '@media (max-width: 899px)' : {
      display: 'none'
    },
    height: "100%",
    display:"flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    fontSize: "1.2em"
  }
}));

const Layout = (props) => {
  //   let isActive = this.context.router.isActive(this.props.to, true);
  //   let className = isActive ? "active" : "";
  const classes = withStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const toggleDrawer = () => {
    setOpenDrawer((drawerOpen) => !drawerOpen);
  };

  const signOut = async () => {
    try {
      await Auth.signOut();
      console.log("logout successful");
      history.push("./");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleSignOut = () => {
    signOut();
  };

  const drawerObj = [
    {
      title: "Dashboard",
      href: "/dashboard",
      pagekey: "dashboard",
    },
    {
      title: "Communications",
      href: "/communications",
      pagekey: "communications",
    },
    {
      title: "Profile",
      href: "/myaccount",
      pagekey: "myaccount",
    },
  ];

  const DrawerList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {drawerObj.map((obj) => (
          <Link key={obj.href} className={classes.drawerLink} to={obj.href}>
            <ListItem
              className={`${classes.drawerItem} ${
                splitLocation[1] === obj.pagekey ? classes.active : ""
              } `}
              button
              key={obj.title}
            >
              <ListItemText primary={obj.title} />
            </ListItem>
          </Link>
        ))}
        <ListItem
          className={classes.drawerItem}
          button
          onClick={() => handleSignOut()}
        >
          <ListItemText primary={"Log Out"} />
        </ListItem>
      </List>
    </div>
  );
  return (
    <div className={classes.layoutRoot}>
      <Header />
      <div style={{ display: "flex", justifyContent: "space-between", marginTop: "2%" }}>
        <Link to="/dashboard" style={{textDecoration: "none", display: "flex"}}>
          <img className={classes.payviewLogo} src="/PayView_Icon.png" />
          <Typography className={classes.payviewLogoText}>
            PayView
          </Typography>
        </Link>
        <div className={classes.pageTitleWrapper}>
          <Typography className={classes.pageTitle}>
            {/* {props.pageTitle} */}
          </Typography>
        </div>
        <img
          onClick={toggleDrawer}
          className={classes.hamburgerIcon}
          src="/icons/Hamburger.png"
        />
        <Drawer
          classes={{
            paperAnchorRight: classes.drawerRoot,
          }}
          className={classes.root}
          open={openDrawer}
          onClose={toggleDrawer}
          anchor="right"
        >
          <DrawerList />
        </Drawer>
      </div>
      <div>{props.children}</div>
    </div>
  );
};

export default Layout;
